import { css } from '@emotion/react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { assertActivity, getActivityRiskCarrierLabel } from '@orus.eu/activity'
import { activitySpecificQuestions } from '@orus.eu/dimensions'
import { Avatar, ContentContainerBackoffice, Text, colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'

export default memo(function ActivitySpecificQuestionsDocumentationPage() {
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Questions spécifiques à une activité</BackofficeSectionTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Text variant="h6">Activity</Text>
            </TableCell>
            <TableCell>
              <Text variant="h6">Question</Text>
            </TableCell>
            <TableCell>
              <Text variant="h6">Exclusion</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(activitySpecificQuestions).map(([activityStr, questions]) =>
            questions.map((question) => (
              <TableRow key={`${activityStr}${question.id}`}>
                <TableCell>{getActivityRiskCarrierLabel(assertActivity(activityStr))}</TableCell>
                <TableCell>
                  <Text variant="body1">{question.affirmation}</Text>
                </TableCell>
                <TableCell>
                  {question.excludedOnTrue ? (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: ${spacing[50]};
                      `}
                    >
                      <Avatar icon="ban-regular" size="30" color={colorTokens['color-bg-danger-primary']} />
                      <Text variant="body1">{question.excludedOnTrue}</Text>
                    </div>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </ContentContainerBackoffice>
  )
})
